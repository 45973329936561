import React from "react";
// import ReactLoading from "react-loading";
import './Loading.css';

const Loading = () => {
    return (
        <div id="loader-container">
        <svg id="loader" width="682" height="65" viewBox="0 0 682 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M681.155 32.472C681.155 49.944 667.235 64.248 650.051 64.248C632.867 64.248 618.947 49.944 618.947 32.472C618.947 15 632.867 0.791992 650.051 0.791992C667.235 0.791992 681.155 15 681.155 32.472ZM630.947 32.472C630.947 43.032 639.491 51.672 650.051 51.672C660.611 51.672 669.155 43.032 669.155 32.472C669.155 21.912 660.611 13.272 650.051 13.272C639.491 13.272 630.947 21.912 630.947 32.472Z" fill="black"/>
        <path d="M588.149 11.832V2.13599H613.205L613.301 44.472C613.301 54.936 605.045 63.48 594.965 63.48C584.789 63.48 576.629 54.936 576.629 44.472V42.36H587.669V45.336C587.669 48.696 590.165 52.056 594.965 52.056C598.613 52.056 601.301 48.888 601.301 44.568V11.832H588.149Z" fill="black"/>
        <path d="M574.561 32.472C574.561 49.944 560.641 64.248 543.457 64.248C526.273 64.248 512.353 49.944 512.353 32.472C512.353 15 526.273 0.791992 543.457 0.791992C560.641 0.791992 574.561 15 574.561 32.472ZM524.353 32.472C524.353 43.032 532.897 51.672 543.457 51.672C554.017 51.672 562.561 43.032 562.561 32.472C562.561 21.912 554.017 13.272 543.457 13.272C532.897 13.272 524.353 21.912 524.353 32.472Z" fill="black"/>
        <path d="M481.556 11.832V2.13599H506.612L506.708 44.472C506.708 54.936 498.452 63.48 488.372 63.48C478.196 63.48 470.036 54.936 470.036 44.472V42.36H481.076V45.336C481.076 48.696 483.572 52.056 488.372 52.056C492.02 52.056 494.708 48.888 494.708 44.568V11.832H481.556Z" fill="black"/>
        <path d="M437.998 1.944C454.51 1.944 467.95 15.576 467.95 32.376C467.95 49.176 454.51 62.904 437.998 62.904H417.07V1.944H437.998ZM437.998 50.328C447.79 50.328 455.95 42.168 455.95 32.376C455.95 22.68 447.79 14.424 437.998 14.424H428.974V50.328H437.998Z" fill="black"/>
        <path d="M371.412 19.704V63H359.508V2.13599H372.66L396.372 47.928V2.13599H408.372V63H394.26L371.412 19.704Z" fill="black"/>
        <path d="M355.339 62.904H342.571L337.963 50.136H313.867L309.259 62.904H296.587L320.203 2.04001H331.627L355.339 62.904ZM317.419 40.44H334.507L325.963 16.728L317.419 40.44Z" fill="black"/>
        <path d="M292.377 63H280.377V38.232H252.537V63H240.633V2.13599H252.537V26.616H280.377V2.13599H292.377V63Z" fill="black"/>
        <path d="M225.812 29.88C228.308 31.032 230.516 32.856 231.668 34.872C233.492 38.04 234.356 41.304 234.356 44.76C234.356 51.672 231.476 57.432 226.292 60.696C221.492 63.768 214.964 64.44 210.356 64.44C209.3 64.44 208.148 64.44 207.092 64.344C201.62 64.056 191.636 62.136 186.644 53.208C185.396 51 184.532 48.696 184.052 46.2C184.052 46.008 184.052 45.72 183.956 45.432V44.952H196.436L196.532 45.336C196.628 46.104 196.82 46.776 196.916 47.16C198.164 50.808 201.332 53.208 205.844 53.784C207.572 54.072 209.012 54.168 210.356 54.168C211.7 54.168 213.044 54.072 214.388 53.88C216.404 53.496 218.036 52.92 219.188 52.056C221.204 50.52 222.164 47.928 221.684 45.048C221.684 44.856 221.684 44.76 221.588 44.664C220.724 40.728 216.98 39.864 214.964 39.384C212.468 38.808 209.972 38.424 207.572 38.04H207.188C206.516 37.944 205.844 37.752 205.172 37.656C196.34 36.312 190.196 33.24 186.74 28.728C184.916 26.328 183.956 23.256 183.956 19.8C183.956 14.712 185.78 9.81599 188.852 6.74399C192.884 2.61599 199.124 0.695984 207.956 0.695984C217.364 0.695984 224.468 3.47999 228.98 9.04799C230.996 11.64 232.052 14.136 232.532 15.768L232.628 16.152L221.972 18.936L221.876 18.648C220.148 13.464 215.348 10.392 209.108 10.392C208.916 10.392 208.532 10.392 208.532 10.392C208.148 10.296 207.764 10.296 207.38 10.296C200.948 10.296 197.204 12.408 196.436 16.632C195.284 21.816 200.372 23.832 204.212 24.792C206.9 25.464 209.684 25.944 212.372 26.424L213.236 26.52C217.076 27.192 220.916 28.152 224.564 29.4C224.948 29.496 225.428 29.688 225.812 29.88Z" fill="black"/>
        <path d="M166.493 37.368V2.04001H178.493V36.408C178.493 51.768 166.397 64.344 151.613 64.344C136.733 64.344 124.733 51.768 124.733 36.408V2.04001H136.637V37.368C136.637 45.816 143.357 52.728 151.613 52.728C159.773 52.728 166.493 45.816 166.493 37.368Z" fill="black"/>
        <path d="M118.914 40.632C115.362 54.552 102.978 64.344 88.7695 64.344C71.5855 64.344 57.6655 50.136 57.6655 32.568C57.6655 15.096 71.5855 0.791992 88.7695 0.791992C102.018 0.791992 113.922 9.43199 118.242 22.2L118.434 22.68H105.186L105.09 22.488C101.538 16.824 95.4895 13.368 88.7695 13.368C78.2095 13.368 69.6655 22.008 69.6655 32.568C69.6655 43.224 78.2095 51.768 88.7695 51.768C95.8735 51.768 102.306 47.928 105.666 41.592L106.722 39.576H92.4175V30.84H119.874V31.224C119.97 31.704 119.97 32.184 119.97 32.664C119.97 34.584 119.778 36.6 119.394 38.424L119.298 39.096C119.202 39.48 119.106 39.96 119.01 40.344L118.914 40.632Z" fill="black"/>
        <path d="M59.464 62.904H46.696L42.088 50.136H17.992L13.384 62.904H0.712036L24.328 2.04001H35.752L59.464 62.904ZM21.544 40.44H38.632L30.088 16.728L21.544 40.44Z" fill="black"/>
        </svg>


        </div>
    )
}

export default Loading