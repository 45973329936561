import React from 'react';

const Home = () => {
    return (
        <div className="container">
            <h1>Hello World This Is Home</h1>

        </div>
    )
}

export default Home